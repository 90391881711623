<template>
  <div id="store">
    <van-nav-bar title="线下门店" left-arrow  @click-left="$router.go(-1)" />
    <div class="banner">
      <img src="https://site-cdn.huami.com/files/amazfit/cn/service/store/store-banner-mb.jpg" alt="">
    </div>
    <div class="tit">
      <h2>品牌线下门店</h2>
      <div class="area">
        <h3>地区</h3>
        <div class="sel">
          <select @change="proviceChange" v-model="province">
            <option v-for="(item, idx) in provinces" :key="idx"  :value="item.id">{{item.name}}</option>
          </select>
          <select @change="cityChange" v-model="city">
            <option v-for="(item, idx) in cities" :key="idx"  :value="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="items">
      <div v-for="item in stores" :key="item.id" class="item">
        <div class="i1">{{item.name}}</div>
        <div class="i2">{{item.address}}</div>
        <div class="i3">
          <ul>
            <li>
              <img src="https://site-cdn.huami.com/files/amazfit/icon/store-ico1.png" alt="">
              <span>营业时间 </span>
              <p>{{item.work_time}}</p>
            </li>
            <li>
              <img src="https://site-cdn.huami.com/files/amazfit/icon/store-ico2.png" alt="">
              <span>联系电话</span>
              <p>{{item.telephone}}</p>
            </li>
            <li>
              <img src="https://site-cdn.huami.com/files/amazfit/icon/store-ico3.png" alt="">
              <span>销售产品</span>
              <p>{{item.sale_product}}</p>
            </li>
          </ul>
        </div>
        <div class="i4">
          <a :href="item.url">交通线路和地图 ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'store',
  data () {
    return {
      baseUrl: 'https://api-site.huami.com/v1/',
      provinces: [{ id: '', name: '请选择省份' }],
      cities: [{ id: '', name: '请选择市' }],
      province: '',
      city: '',
      page: 1,
      stores: [],
      scroll: 0,
      flag: true
    }
  },
  mounted () {
    this.loadProvince()
    this.loadStores()
    window.addEventListener('scroll', this.loadMore, false)
  },
  methods: {
    loadProvince () {
      this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
      this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
      this.$http.get(this.baseUrl + 'regions').then((res) => {
        this.provinces = this.provinces.concat(res.data)
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    proviceChange () {
      this.page = 1
      this.stores = []
      // this.flag = true
      this.cities = [{ id: '', name: '请选择市' }]
      this.city = ''
      this.loadCity()
      this.loadStores()
    },
    loadCity () {
      this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
      this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
      const url = this.baseUrl + 'regions/' + this.province + '/children'
      this.$http.get(url).then((res) => {
        this.cities = [{ id: '', name: '请选择市' }].concat(res.data)
      }).catch((error) => {
        this.$toast.fail(error.response.data.message)
      })
    },
    cityChange () {
      this.page = 1
      this.stores = []
      // this.flag = true
      this.loadStores()
    },
    loadStores () {
      this.$http.defaults.headers.common['X-SITE'] = 'amazfit'
      this.$http.defaults.headers.common['X-LOCALE'] = 'cn'
      const jso = {
        'page': this.page,
        'per-page': 10,
        'province_id': this.province,
        'city_id': this.city
      }
      this.$http.get(this.baseUrl + 'stores', { params: jso }).then((res) => {
        console.log('dadaa', res.data.length)
        if (res.data.length === 0) {
          if (this.page === 1) {
            return this.$toast('暂无数据')
          }
          return this.$toast('数据加载完毕')
        }
        res.data.map(e => {
          let location = e.longitude_latitude.split(',')
          const weizhi = location[1] + ',' + location[0]
          e.url = `https://api.map.baidu.com/marker?location=${weizhi}&title=${e.name}&content=${e.address}&output=html&src=amazfit`
        })
        this.stores = this.stores.concat(res.data)
        this.flag = true
        this.page++
      }).catch(() => {
        this.$toast.fail('出错了，稍后再试')
      })
    },
    loadMore () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const wHeight = document.documentElement.clientHeight || document.body.clientHeight
      const sHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      var startflag = scrollTop + wHeight >= sHeight - 50
      if (startflag && this.flag && (this.page > 1)) {
        console.log('sss', this.flag)
        this.flag = false
        this.loadStores()
      }
    }
  },
  destory () {
    window.removeEventListener('scroll', this.loadMore, false)
  }
}
</script>
<style lang="scss">
#store {
  background: #F5F5F5;
  color: #000;
  h2,h3 {
    font-weight: normal;
    margin: 0;
  }
  .banner {
    img {
      width: 100%;
      vertical-align: middle;
    }
  }
  .tit {
    padding: 9vw;
    background: #fff;
    h2 {
      font-size: 5.56vw;
      font-weight: normal;
      border-bottom: .09vw solid #979797;
      padding-bottom: 1.6vw;
    }
    h3 {
      font-size: 4vw;
      margin-top: 6.1vw;
      margin-bottom: 1.7vw;
    }
    .sel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      select {
        border: .09vw solid #C6C6C6;
        background: #fff;
        height: 9vw;
        width: 38vw;
        font-size: 3.3vw;
        padding-left: 2vw;
        outline: none;
      }
    }
  }
  .items {
    padding: 9vw;
    .item {
      background: #fff;
      border-radius: 1.85vw;
      padding: 5.7vw 4.5vw;
      margin-bottom: 5.47vw;
      .i1 {
        font-size: 4.5vw;
        color: #000;
        margin-bottom: 1vw;
      }
      .i2 {
        font-size: 3.33vw;
        color: #333;
        margin-bottom: 5vw;
      }
      .i3 {
        color: #666666;
        font-size: 2.6vw;
        margin-bottom: 6.76vw;
        li {
          border-top: .19vw solid #979797;
          display: flex;
          align-items: center;
          padding: 2.5vw 0;
          img {
            width: 2.5vw;
            margin-right: 1.3vw;
          }
          span {
            width: 15vw;
            flex-shrink: 0;
          }
          p {
            margin: 0;
          }
        }
      }
      .i4 {
        a {
          display: block;
          margin: 0 auto;
          width: 33vw;
          height: 8.6vw;
          text-align: center;
          line-height: 8.6vw;
          background: #FCC800;
          color: #fff;
          font-size: 3vw;
          border-radius: 4.3vw;
        }
      }
    }
  }
}
</style>
